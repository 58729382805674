import React from "react"
import { Link } from "gatsby"
import Container from "../components/layouts/container/Container"
import H1 from "../components/typography/H1"
import P from "../components/typography/P"
import Layout from "./../components/layouts/Default"

export default function page({ location }) {
  return (
    <Layout location={location} title="Kontakt | Die Summe aller Dinge" desc="">
      <Container className="my-8 mmd:my-16">
        <div className="max-w-md mx-auto text-center">
          <H1 className="mb-6">Kontakt</H1>
          <P>
            Wenn Du uns kontaktieren möchtest, dann laden wir Dich ein das
            untere Kontaktformular auszufüllen oder schreibe uns auf{" "}
            <a
              href="https://www.instagram.com/summeallerdinge/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Instagram
            </a>
            .
          </P>
        </div>
        <div className="mt-12">
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            action="/nachricht-gesendet"
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div>
              <label
                htmlFor="vorname"
                className="block text-sm font-medium text-gray-700"
              >
                Vorname
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="Vorname"
                  id="vorname"
                  autoComplete="given-name"
                  className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  required
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-Mail
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="E-Mail"
                  type="email"
                  autoComplete="email"
                  className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  required
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="nachricht"
                className="block text-sm font-medium text-gray-700"
              >
                Nachricht
              </label>
              <div className="mt-1">
                <textarea
                  id="nachricht"
                  name="nachricht"
                  rows={4}
                  className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  defaultValue={""}
                  required
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <P className="text-xs">
                Wir respektieren Dein Vertrauen und behandeln Deine Daten mit
                Respekt. Mit dem Absenden dieses Formulars akzeptierst Du unsere{" "}
                <Link to="/datenschutz/" className="font-medium underline">
                  Datenschutzerklärung
                </Link>
                .
              </P>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white transition-colors duration-300 bg-black border border-transparent rounded-md shadow hover:bg-black700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-bg-black700 focus:ring-white"
              >
                Nachricht senden
              </button>
            </div>
          </form>
        </div>
      </Container>
    </Layout>
  )
}
