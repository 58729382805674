import React from "react"
const P = ({ children, className, textColor }) => {
  return (
    <p
      className={`leading-relaxed ${
        textColor ? textColor : "text-gray-500"
      } ${className}`}
    >
      {children}
    </p>
  )
}

export default P
